import { useMutation, useQueryClient } from '@tanstack/react-query';

import { tasksApi } from '../api';
import { AddTodoTaskItemProps } from '../types';
import { useRecoilState } from 'recoil';
import { taskErrorSnackBarAtom } from '../recoil';
import { useEffect } from 'react';

interface UseAddTodoTaskParams {
  boardId: string;
  userId: string;
  newTaskInfo: AddTodoTaskItemProps;
}

export const useAddTodoTask = () => {
  const queryClient = useQueryClient();
  const [_, setTaskErrorSnackbar] = useRecoilState(taskErrorSnackBarAtom);

  const addTaskMutation = useMutation({
    mutationFn: ({ boardId, userId, newTaskInfo }: UseAddTodoTaskParams) => {
      return tasksApi.addTodoTask(boardId, userId, newTaskInfo).then(({ data }) => data.taskId);
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.todo.tasks'] });
    },
  });

  useEffect(() => {
    if (addTaskMutation.error) {
      setTaskErrorSnackbar(addTaskMutation.error.message);
    }
  }, [addTaskMutation.error]);

  return {
    addTodoTask: addTaskMutation.mutateAsync,
    ...addTaskMutation,
  };
};
