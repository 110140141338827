import { useMutation, useQueryClient } from '@tanstack/react-query';

import { tasksApi } from '../api';
import { FirestoreTaskItem, TasksByDate } from '../types';
import { merge, unionBy } from 'lodash';
import { TaskItem } from '@demind-inc/core';
import { useEffect } from 'react';
import { taskErrorSnackBarAtom } from '../recoil';
import { useRecoilState } from 'recoil';

interface MovedTaskParam {
  prevDate?: string; //YYYY-MM-DD
  newDate?: string; //YYYY-MM-DD
  wasOverdue?: boolean;
}

interface UseUpdateTodoTaskParams {
  boardId: string;
  taskId: string;
  userId: string;
  newTaskInfo: Partial<FirestoreTaskItem>;
  movedTaskParam?: MovedTaskParam; // Used for updating the state while mutating between dates
}

export const useUpdateTodoTask = () => {
  const queryClient = useQueryClient();
  const [_, setTaskErrorSnackbar] = useRecoilState(taskErrorSnackBarAtom);

  const updateTaskMutation = useMutation({
    mutationFn: ({ boardId, taskId, userId, newTaskInfo }: UseUpdateTodoTaskParams) => {
      return tasksApi.updateTodoTask(taskId, boardId, userId, newTaskInfo).then(({ data }) => data);
    },
    onMutate: async ({ taskId, newTaskInfo, movedTaskParam }) => {
      await queryClient.cancelQueries({ queryKey: ['lifestack.todo.tasks'] });
      await queryClient.cancelQueries({ queryKey: ['lifestack.todo.overdueTasks'] });
      queryClient.setQueriesData<TasksByDate>(
        { queryKey: ['lifestack.todo.tasks'] },
        (prevTasks) => {
          const prevTaskDate = prevTasks?.date;

          let updatedTasks = prevTasks?.tasks?.map((task) =>
            task.taskId === taskId ? merge({}, task, newTaskInfo) : task
          );

          // Update the state of the task when moving between dates
          if (!!movedTaskParam && movedTaskParam.prevDate === prevTaskDate) {
            updatedTasks = updatedTasks?.filter((task) => task.taskId !== taskId);
          }
          if (!!movedTaskParam && movedTaskParam.newDate === prevTaskDate) {
            const addedTask = { ...newTaskInfo, taskId } as TaskItem;
            updatedTasks = unionBy(updatedTasks, [addedTask], 'taskId');
          }

          return {
            date: prevTaskDate,
            tasks: updatedTasks,
          };
        }
      );

      // Update overdue tasks
      //FIXME: wasOverDue returns false even in case of moving overdue task
      if (movedTaskParam?.wasOverdue) {
        queryClient.setQueriesData<TaskItem[]>(
          { queryKey: ['lifestack.todo.overdueTasks'] },
          (prevTasks) => prevTasks?.filter((task) => task.taskId !== taskId)
        );
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.todo.tasks'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.todo.overdueTasks'] });
    },
  });

  useEffect(() => {
    if (updateTaskMutation.error) {
      setTaskErrorSnackbar(updateTaskMutation.error.message);
    }
  }, [updateTaskMutation.error]);

  return {
    updateTodoTask: updateTaskMutation.mutateAsync,
    isUpdating: updateTaskMutation.isPending,
    ...updateTaskMutation,
  };
};
