import { FC, useMemo } from 'react';
import { Button, Snackbar } from '@mui/material';
import { upperFirst } from 'lodash';

import { AVAILABLE_TODO_INTEGRATIONS_TYPE, taskErrorSnackBarAtom } from '../../../data-access';
import { TodoIntegrationType } from '@demind-inc/core';
import { useRecoilState } from 'recoil';

interface TaskErrorSnackBarProps {
  onAction: (type: TodoIntegrationType) => void;
}

const TaskErrorSnackBar: FC<TaskErrorSnackBarProps> = ({ onAction }) => {
  const [taskErrorSnackbar, setTaskErrorSnackbar] = useRecoilState(taskErrorSnackBarAtom);

  // If unauthenticated error, show the actions
  // TODO: This should be handed in react query
  const showActions = useMemo(() => taskErrorSnackbar.includes('401'), [taskErrorSnackbar]);

  return (
    <Snackbar
      open={!!taskErrorSnackbar}
      onClose={(_, reason) => reason === 'timeout' && setTaskErrorSnackbar('')}
      message={`[Task] Error.  ${showActions ? 'Re-connect your todo-list : ' : ''}`}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      action={
        showActions && (
          <>
            {AVAILABLE_TODO_INTEGRATIONS_TYPE.map((type) => (
              <Button key={type} color="secondary" size="small" onClick={() => onAction(type)}>
                {upperFirst(type)}
              </Button>
            ))}
          </>
        )
      }
    />
  );
};

export default TaskErrorSnackBar;
