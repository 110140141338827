import { CalendarEvent, TaskItem } from '@demind-inc/core';
import { DragFromOutsideItemArgs } from 'react-big-calendar/lib/addons/dragAndDrop';
import { useDrop } from 'react-dnd';
import { useCallback, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useRecoilState } from 'recoil';

import { convertTaskToEvent, getParamToUpdateTaskTimeFromCalendar } from '../helpers';
import {
  eventsSnackBarAtom,
  useAuthContext,
  useCalendarContext,
  useCreateCalendarEvent,
  useUpdateTodoTask,
} from '../data-access';
import { getCssVariable, trackEventGA4 } from '../utils';

const primaryColor = getCssVariable('--color-primary');

export const useDnDTaskToCalendar = () => {
  const [draggedNewEvent, setDraggedNewEvent] = useState<CalendarEvent>();
  const [draggedTask, setDraggedTask] = useState<TaskItem>();
  const [__, setEventsSnackbar] = useRecoilState(eventsSnackBarAtom);

  const { createCalendarEvent, status: createCalendarStatus } = useCreateCalendarEvent();
  const { updateTodoTask } = useUpdateTodoTask();
  const { user } = useAuthContext();
  const { mainCalendar } = useCalendarContext();

  const hasDraggedTask = useRef(false);

  const [{ isOver: isTaskOverOnCalendar }, dropTaskOnCalendar] = useDrop(() => ({
    accept: 'TASK',
    hover: (item: TaskItem) => setDraggedTask(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  useEffect(() => {
    if (!draggedTask || hasDraggedTask.current) {
      return;
    }
    setDraggedNewEvent(convertTaskToEvent(draggedTask));
    hasDraggedTask.current = true;
  }, [draggedTask]);

  useEffect(() => {
    if (createCalendarStatus === 'success') {
      setEventsSnackbar('Event created');
    } else if (createCalendarStatus === 'pending') {
      setEventsSnackbar('Event creating...');
    }
  }, [createCalendarStatus]);

  const handleDropEventFromOutside = useCallback(
    debounce((data: DragFromOutsideItemArgs) => {
      const color = mainCalendar?.color || primaryColor;
      const eventFromTask = draggedTask ? convertTaskToEvent(draggedTask) : {};

      const newEvent = {
        ...eventFromTask,
        start: {
          date: dayjs(data.start).toISOString(),
          timeZone: dayjs.tz.guess(),
        },
        end: {
          date: dayjs(data.end).toISOString(),
          timeZone: dayjs.tz.guess(),
        },
        color,
        calendarId: mainCalendar?.calendarId,
      } as CalendarEvent;

      setDraggedNewEvent(newEvent);

      const newEventId = uuidv4().replace(/-/g, '');
      createCalendarEvent({
        userId: user.userId,
        calendarId: mainCalendar?.calendarId!,
        newEventOption: { ...newEvent, eventId: newEventId },
      });

      if (draggedTask) {
        updateTodoTask({
          userId: user.userId,
          boardId: draggedTask.boardId!,
          taskId: draggedTask.taskId!,
          newTaskInfo: {
            ...getParamToUpdateTaskTimeFromCalendar(newEvent),
            appFrom: draggedTask.appFrom,
          },
        });
      }

      setDraggedNewEvent(undefined);
      trackEventGA4('Drag', 'drag_n_drop_task_to_calendar', { event: newEvent.summary });
    }, 10),
    [draggedTask, mainCalendar, user.userId]
  );

  return {
    draggedNewEvent,
    isTaskOverOnCalendar,
    dropTaskOnCalendar,
    handleDropEventFromOutside,
  };
};
