import React, { FC, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { Calendar, dayjsLocalizer, SlotInfo } from 'react-big-calendar';
import dayjs from 'dayjs';
import withDragAndDrop, {
  DragFromOutsideItemArgs,
  EventInteractionArgs,
} from 'react-big-calendar/lib/addons/dragAndDrop';
import { HeatmapDataType } from '@demind-inc/core';

import './CalendarTimeline.scss';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useRecoilValue } from 'recoil';

import { EventBlock } from './EventBlock';
import { RBCEvent } from './types';
import { CustomTimeGutter } from './CustomTimeGutter';
import {
  PeakDipPhase,
  PeakDipStartEndSet,
  selectedDateAtom,
  useCalendarContext,
  useCreateEventMenuContext,
  useEventDetailsMenuContext,
} from '../../../data-access';
import { zoneColorForPeaksDipsPhase } from './constants';
import { getCssVariable } from '../../../utils';
import { CreateEventMenu, EditEventMenu, EventDetailsMenu } from '../EventMenu';
import { transformCreatingEventToRBCEvent } from './helpers';
import { useGeneralSettings } from '../../../hooks/useGeneralSettings';
import { useDnDTaskToCalendar } from '../../../hooks';
import { debounce } from 'lodash';

interface CalendarTimelineProps {
  events: RBCEvent[];
  circadianRhythms: HeatmapDataType[];
  peaksDipsBoundaries?: PeakDipStartEndSet;
  hasNoSleepData?: boolean;
  onEventDrop: (data: EventInteractionArgs<object>) => void;
  onEventResize: (data: EventInteractionArgs<object>) => void;
  className?: string;
}

interface MemoizedCalendarProps extends CalendarTimelineProps {
  backgroundEvents: object[];
  dragFromOutsideItem: () => Partial<RBCEvent>;
  onDropEventFromOutside: (data: DragFromOutsideItemArgs) => void;
  onSelectEvent: (event: RBCEvent) => void;
  onSelectSlot: (slot: SlotInfo) => void;
}

const localizer = dayjsLocalizer(dayjs);
const DnDCalendar = withDragAndDrop(Calendar);
const primaryColor = getCssVariable('--color-primary');

const MemoizedCalendar = React.memo(
  forwardRef<HTMLDivElement, MemoizedCalendarProps>(
    (
      {
        events,
        circadianRhythms,
        peaksDipsBoundaries,
        hasNoSleepData = false,
        onEventDrop,
        onEventResize,
        backgroundEvents,
        dragFromOutsideItem,
        onDropEventFromOutside,
        onSelectEvent,
        onSelectSlot,
        className,
      },
      ref
    ) => {
      const selectedDate = useRecoilValue(selectedDateAtom);
      const {
        generalSettings: { timeFormat },
      } = useGeneralSettings();
      const selectedTimeFormat = timeFormat === '12h' ? 'h a' : 'HH';

      return (
        <div ref={ref} className="calendar-timeline__container">
          <DnDCalendar
            date={selectedDate.toDate()}
            defaultView="day"
            events={events}
            localizer={localizer}
            onEventDrop={onEventDrop}
            onSelectEvent={(event) => onSelectEvent(event as RBCEvent)}
            onEventResize={onEventResize}
            //@ts-ignore
            dragFromOutsideItem={dragFromOutsideItem}
            onDropFromOutside={onDropEventFromOutside}
            onSelectSlot={onSelectSlot}
            resizable
            selectable
            showMultiDayTimes
            step={15}
            dayLayoutAlgorithm="no-overlap"
            scrollToTime={selectedDate.toDate()}
            formats={{
              timeGutterFormat: (date) => {
                if (dayjs(date).minute() === 0) {
                  return dayjs(date).format(selectedTimeFormat);
                }
              },
            }}
            backgroundEvents={backgroundEvents}
            components={{
              timeGutterWrapper: (props: React.PropsWithChildren<{}>) => {
                return (
                  <CustomTimeGutter
                    peaksDipsBoundaries={peaksDipsBoundaries}
                    heatmapData={circadianRhythms}
                    isDefaultHeatmap={hasNoSleepData}
                  >
                    {props.children}
                  </CustomTimeGutter>
                );
              },
              event: (event) => <EventBlock event={event.event} />,
            }}
            toolbar={false}
            eventPropGetter={(event) => {
              const rbcEvent = event as RBCEvent;
              if (rbcEvent.eventId) {
                // Calendar Event
                const isCreatingEvent = rbcEvent.eventId === 'creating';
                return {
                  style: {
                    backgroundColor: isCreatingEvent ? 'white' : `${rbcEvent.color}33`,
                    border: isCreatingEvent ? `solid 1px ${primaryColor}` : 'none',
                    padding: 0,
                  },
                };
              } else {
                // Zone
                return {
                  style: {
                    backgroundColor: `${rbcEvent.color}1A`,
                    border: `1px solid ${rbcEvent.color}`,
                    borderRadius: '0',
                    borderLeft: 'none',
                    borderRight: 'none',
                  },
                };
              }
            }}
            className={clsx('calendar-timeline', className)}
          />
        </div>
      );
    }
  )
);

const CalendarTimeline: FC<CalendarTimelineProps> = ({
  events,
  circadianRhythms,
  peaksDipsBoundaries,
  hasNoSleepData = false,
  onEventDrop,
  onEventResize,
  className,
}) => {
  const { creatingEvent, handleSelectSlot, clearCreatingEvent } = useCreateEventMenuContext();
  const { visibleMenuMode, selectedEvent, handleSelectEvent, clearSelectedEvent } =
    useEventDetailsMenuContext();
  const { mainCalendar } = useCalendarContext();

  const {
    draggedNewEvent: defaultDraggedEvent,
    dropTaskOnCalendar,
    handleDropEventFromOutside,
  } = useDnDTaskToCalendar();
  const firstRender = useRef(false);
  const [draggedEvent, setDraggedEvent] = useState<Partial<RBCEvent> | null>(null);

  const backgroundEvents = useMemo(
    () =>
      peaksDipsBoundaries
        ? Object.entries(peaksDipsBoundaries).map(([phase, { start, end }]) => ({
            start: new Date(start),
            end: new Date(end),
            color: zoneColorForPeaksDipsPhase[phase as PeakDipPhase],
          }))
        : [],
    [peaksDipsBoundaries]
  );

  const eventWithCreatingOne = useMemo(
    () => (creatingEvent ? [...events, transformCreatingEventToRBCEvent(creatingEvent)] : events),
    [creatingEvent, events]
  );

  // Initialize dragged event
  useEffect(() => {
    if (firstRender.current || !defaultDraggedEvent) {
      return;
    }

    setDraggedEvent({
      title: defaultDraggedEvent.summary,
      eventId: 'dragging',
      color: mainCalendar.color || primaryColor,
    });
    firstRender.current = true;
  }, [defaultDraggedEvent, mainCalendar]);

  const dragFromOutsideItem = useCallback(
    debounce(() => draggedEvent, 10),
    [draggedEvent]
  );

  return (
    <>
      <MemoizedCalendar
        ref={dropTaskOnCalendar}
        events={eventWithCreatingOne}
        circadianRhythms={circadianRhythms}
        peaksDipsBoundaries={peaksDipsBoundaries}
        hasNoSleepData={hasNoSleepData}
        onEventDrop={onEventDrop}
        onEventResize={onEventResize}
        onSelectEvent={handleSelectEvent}
        onDropEventFromOutside={(data) => {
          handleDropEventFromOutside(data);
          setDraggedEvent(null);
        }}
        onSelectSlot={handleSelectSlot}
        backgroundEvents={backgroundEvents}
        dragFromOutsideItem={dragFromOutsideItem} //TODO: improve the reflection speed while dragging
        className={className}
      />
      <CreateEventMenu visible={!!creatingEvent} onClose={clearCreatingEvent} />
      <EventDetailsMenu
        visible={!!selectedEvent && visibleMenuMode === 'details'}
        onClose={clearSelectedEvent}
      />
      <EditEventMenu
        visible={!!selectedEvent && visibleMenuMode === 'edit'}
        onClose={clearSelectedEvent}
      />
    </>
  );
};

export default CalendarTimeline;
