import { FC, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { EventInteractionArgs } from 'react-big-calendar/lib/addons/dragAndDrop';
import { useRecoilState, useRecoilValue } from 'recoil';
import { debounce } from 'lodash';
import { CalendarTaskFrom } from '@demind-inc/core';

import './CalendarView.scss';

import { useOvernightSummary, useStartTodoAuth } from '../../../hooks';
import {
  eventsSnackBarAtom,
  selectedDateAtom,
  useAuthContext,
  useCalendarContext,
  useCircadianContext,
  useUpdateCalendarEvent,
  UseUpdateCalendarEventParams,
  useUpdateTodoTask,
} from '../../../data-access';
import {
  CalendarEventSnackBar,
  CalendarTimeline,
  DateBox,
  PeakEnergy,
  RBCEvent,
  TaskErrorSnackBar,
  transformEventToRBCEvent,
} from '../../../components';
import { getParamToUpdateTaskTimeFromCalendar } from '../../../helpers';
import { trackEventGA4 } from '../../../utils';

interface CalendarViewProps {
  className?: string;
}

const CalendarView: FC<CalendarViewProps> = ({ className }) => {
  const [events, setEvents] = useState<RBCEvent[]>([]);
  const [_, setEventsSnackbar] = useRecoilState(eventsSnackBarAtom);
  const selectedDate = useRecoilValue(selectedDateAtom);

  const { calendarEvents, findCalendarItem, checkCalendarHassAccessToModifyEvent } =
    useCalendarContext();
  const { circadianRhythms, peaksDipsBoundaries, peakEnergy } = useCircadianContext();
  const { updateCalendarEvent, status: updateCalendarEventStatus } = useUpdateCalendarEvent();
  const { user } = useAuthContext();
  const { updateTodoTask } = useUpdateTodoTask();
  const { handleOpenTodoAuth } = useStartTodoAuth({});
  const { hasNoSleepData } = useOvernightSummary({ date: selectedDate });

  useEffect(() => {
    if (updateCalendarEventStatus === 'success') {
      setEventsSnackbar('Event updated');
    } else if (updateCalendarEventStatus === 'pending') {
      setEventsSnackbar('Event updating...');
    }
  }, [updateCalendarEventStatus]);

  useEffect(() => {
    const transformedEvents = calendarEvents.map((e) => transformEventToRBCEvent(e));
    const eventsWithCalendarColor = transformedEvents.map((e) => ({
      ...e,
      calendarColor: e.calendarId ? findCalendarItem(e.calendarId)?.color : '',
    }));
    setEvents(eventsWithCalendarColor);
  }, [calendarEvents]);

  const debouncedUpdateCalendarEventAndTask = useCallback(
    debounce(
      (updateCalendarArgs: UseUpdateCalendarEventParams, updateTaskArgs?: CalendarTaskFrom) => {
        updateCalendarEvent(updateCalendarArgs);

        if (updateTaskArgs) {
          updateTodoTask({
            userId: updateCalendarArgs.userId,
            boardId: updateTaskArgs.boardId,
            taskId: updateTaskArgs.taskId,
            newTaskInfo: {
              ...getParamToUpdateTaskTimeFromCalendar(updateCalendarArgs.newEventOption),
              appFrom: updateTaskArgs.from,
            },
          });
        }
      },
      2000
    ),
    []
  );

  const onEventResize = (data: EventInteractionArgs<object>) => {
    const { start, end, event } = data;
    const targetEvent = event as RBCEvent;

    const hasAccesToModify = checkCalendarHassAccessToModifyEvent(targetEvent.calendarId!);

    if (!hasAccesToModify) {
      setEventsSnackbar('You do not have access to modify this event');
      return;
    }

    if (dayjs(start).isSameOrAfter(dayjs(end))) {
      return;
    }

    setEvents((prev) =>
      prev.map((e) => (e.eventId === targetEvent.eventId ? ({ ...e, start, end } as RBCEvent) : e))
    );

    if (!targetEvent.calendarId) {
      return;
    }

    debouncedUpdateCalendarEventAndTask(
      {
        userId: user.userId,
        calendarId: targetEvent.calendarId!,
        eventId: targetEvent.eventId,
        newEventOption: {
          start: {
            date: dayjs(start).toISOString(),
            timeZone: dayjs.tz.guess(),
          },
          end: {
            date: dayjs(end).toISOString(),
            timeZone: dayjs.tz.guess(),
          },
        },
      },
      targetEvent.taskFrom
    );
    trackEventGA4('Drag', 'resize_calendar_event_by_drag');
  };

  const onEventDrop = (data: EventInteractionArgs<object>) => {
    const { start, end, event } = data;
    const targetEvent = event as RBCEvent;

    const hasAccesToModify = checkCalendarHassAccessToModifyEvent(targetEvent.calendarId!);

    if (!hasAccesToModify) {
      setEventsSnackbar('You do not have access to modify this event');
      return;
    }

    if (dayjs(start).isSameOrAfter(dayjs(end))) {
      return;
    }

    setEvents((prev) =>
      prev.map((e) => (e.eventId === targetEvent.eventId ? ({ ...e, start, end } as RBCEvent) : e))
    );

    if (!targetEvent.calendarId) {
      return;
    }

    debouncedUpdateCalendarEventAndTask(
      {
        userId: user.userId,
        calendarId: targetEvent.calendarId!,
        eventId: targetEvent.eventId,
        newEventOption: {
          start: {
            date: dayjs(start).toISOString(),
            timeZone: dayjs.tz.guess(),
          },
          end: {
            date: dayjs(end).toISOString(),
            timeZone: dayjs.tz.guess(),
          },
        },
      },
      targetEvent.taskFrom
    );
    trackEventGA4('Drag', 'move_calendar_event_by_drag');
  };

  return (
    <div className={clsx('calendar-view', className)}>
      <div className="calendar-view__data-header">
        <DateBox date={selectedDate} />
        <PeakEnergy
          score={peakEnergy ? Math.round(peakEnergy * 100).toString() : '--'}
          hasNoSleepData={hasNoSleepData}
        />
      </div>
      <CalendarTimeline
        circadianRhythms={circadianRhythms}
        peaksDipsBoundaries={peaksDipsBoundaries}
        events={events}
        hasNoSleepData={hasNoSleepData}
        onEventDrop={onEventDrop}
        onEventResize={onEventResize}
      />
      <CalendarEventSnackBar />
      <TaskErrorSnackBar onAction={(type) => handleOpenTodoAuth(type)} />
    </div>
  );
};

export default CalendarView;
